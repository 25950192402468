// src/components/Navbar.jsx
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaShoppingCart, FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo.png";
import { AuthContext } from "../Context/AuthContext"; // Importer AuthContext
import { useCart } from "../Context/CartContext"; // Importer CartContext

const Navbar = ({ toggleCart }) => {
  const { user, logout } = useContext(AuthContext);
  const { state } = useCart();
  const [isOpen, setIsOpen] = useState(false);

  // Calculer la quantité totale de produits dans le panier
  const totalQuantity = state.products.reduce(
    (acc, product) => acc + product.quantity,
    0
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 fixed w-full z-50 shadow h-16">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo et Nom du Site */}
        <div className="flex items-center">
          <img src={logo} className="h-10 w-auto mr-3" alt="Logo" />
          <Link to="/" className="text-white text-xl font-bold">
            Epices Royales
          </Link>
        </div>

        {/* Menu Desktop */}
        <div className="hidden lg:flex space-x-6">
          <Link
            to="/"
            className="text-gray-300 hover:text-white transition duration-200"
          >
            Home
          </Link>
          <Link
            to="/epices"
            className="text-gray-300 hover:text-white transition duration-200"
          >
            Epices
          </Link>
          <Link
            to="/amuse-bouche"
            className="text-gray-300 hover:text-white transition duration-200"
          >
            Amuse Bouche
          </Link>
          <Link
            to="/mugs"
            className="text-gray-300 hover:text-white transition duration-200"
          >
            Mugs
          </Link>
          {user && user.role === "admin" && (
            <Link
              to="/admin"
              className="text-gray-300 hover:text-white transition duration-200"
            >
              Admin
            </Link>
          )}
          {user && (
            <>
              <Link
                to="/my-orders"
                className="text-gray-300 hover:text-white transition duration-200"
              >
                Mes commandes
              </Link>
              <Link
                to="/user-personal"
                className="text-gray-300 hover:text-white transition duration-200"
              >
                Mon compte
              </Link>
            </>
          )}
        </div>

        {/* Icônes Desktop */}
        <div className="hidden lg:flex items-center space-x-4">
          <div className="relative">
            <FaShoppingCart
              className="text-gray-300 hover:text-white cursor-pointer"
              size="1.5em"
              onClick={toggleCart}
              aria-label="Panier"
            />
            {totalQuantity > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs font-bold">
                {totalQuantity}
              </span>
            )}
          </div>
          {user ? (
            <div className="flex items-center space-x-2">
              <span className="text-gray-300">Bienvenue, {user.username}</span>
              <button
                onClick={logout}
                className="text-gray-300 hover:text-white transition duration-200"
              >
                Logout
              </button>
            </div>
          ) : (
            <Link
              to="/login"
              className="flex items-center text-gray-300 hover:text-white transition duration-200"
            >
              <FaUser className="mr-2" /> Login
            </Link>
          )}
        </div>

        {/* Menu Mobile Button */}
        <div className="lg:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-gray-300 hover:text-white focus:outline-none"
            aria-label="Menu"
            aria-expanded={isOpen}
          >
            {isOpen ? <FaTimes size="1.5em" /> : <FaBars size="1.5em" />}
          </button>
        </div>
      </div>

      {/* Menu Mobile */}
      {isOpen && (
        <div className="lg:hidden bg-gray-800 px-4 pb-4">
          <Link
            to="/"
            className="block text-gray-300 hover:text-white py-2"
            onClick={toggleMenu}
          >
            Home
          </Link>
          <Link
            to="/epices"
            className="block text-gray-300 hover:text-white py-2"
            onClick={toggleMenu}
          >
            Epices
          </Link>
          <Link
            to="/amuse-bouche"
            className="block text-gray-300 hover:text-white py-2"
            onClick={toggleMenu}
          >
            Amuse Bouche
          </Link>
          <Link
            to="/mugs"
            className="block text-gray-300 hover:text-white py-2"
            onClick={toggleMenu}
          >
            Mugs
          </Link>
          {user && user.role === "admin" && (
            <Link
              to="/admin"
              className="block text-gray-300 hover:text-white py-2"
              onClick={toggleMenu}
            >
              Admin
            </Link>
          )}
          {user && (
            <>
              <Link
                to="/my-orders"
                className="block text-gray-300 hover:text-white py-2"
                onClick={toggleMenu}
              >
                Mes commandes
              </Link>
              <Link
                to="/user-personal"
                className="block text-gray-300 hover:text-white py-2"
                onClick={toggleMenu}
              >
                Mon compte
              </Link>
            </>
          )}
          {/* Section Cart et User */}
          <div className="mt-4 border-t border-gray-700 pt-4">
            <div className="flex items-center justify-between">
              <div className="relative">
                <FaShoppingCart
                  className="text-gray-300 hover:text-white cursor-pointer"
                  size="1.5em"
                  onClick={() => {
                    toggleCart();
                    toggleMenu();
                  }}
                  aria-label="Panier"
                />
                {totalQuantity > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs font-bold">
                    {totalQuantity}
                  </span>
                )}
              </div>
              {user ? (
                <div className="flex items-center space-x-2">
                  <span className="text-gray-300">
                    Bienvenue, {user.username}
                  </span>
                  <button
                    onClick={logout}
                    className="text-gray-300 hover:text-white transition duration-200"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <Link
                  to="/login"
                  className="flex items-center text-gray-300 hover:text-white transition duration-200"
                >
                  <FaUser className="mr-2" /> Login
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
